/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export enum FormGroupLayoutMode {
  INNER = 'inner',
  OUTER = 'outter',
}

export enum FormItemComponentType {
  MODAL = 'modal',
}

export enum InteractionMouseEvent {
  Left = 'left',
  Right = 'right',
}

export enum InteractionCategory {
  JumpToChart = 'jumpToChart',
  JumpToDashboard = 'jumpToDashboard',
  JumpToUrl = 'jumpToURL',
}

export enum InteractionAction {
  Redirect = 'redirect',
  Window = 'modal',
  Dialog = 'dialog',
}

export enum InteractionFieldRelation {
  Auto = 'auto',
  Customize = 'customize',
}

export enum InteractionRelationType {
  Field = 'field',
  Variable = 'variable',
}

export enum InteractionFieldMapper {
  All = 'all',
  Customize = 'customize',
}
