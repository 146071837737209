import {
  DEFAULT_AUTHORIZATION_TOKEN_EXPIRATION,
  StorageKeys,
} from 'globalConstants';
import Cookies from 'js-cookie';

let tokenExpiration = DEFAULT_AUTHORIZATION_TOKEN_EXPIRATION;

export function setTokenExpiration(expires: number) {
  tokenExpiration = expires;
}

export function getToken() {
  return Cookies.get(StorageKeys.AuthorizationToken);
}

export function getSessionId() {
  return Cookies.get(StorageKeys.SessionId);
}

export function setToken(token: string) {
  Cookies.set(StorageKeys.AuthorizationToken, token, {
    expires: new Date(new Date().getTime() + tokenExpiration),
  });

  const sessionId = getSessionId();
  if (sessionId) {
    setSessionId(sessionId);
  }
}

export function setSessionId(sessionId: string) {
  Cookies.set(StorageKeys.SessionId, sessionId, {
    expires: new Date(new Date().getTime() + tokenExpiration),
  });
}

export function removeToken() {
  Cookies.remove(StorageKeys.AuthorizationToken);
}
