/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

function save(key, value) {
  sessionStorage.setItem(key, value);
}

function get(key) {
  return sessionStorage.getItem(key);
}

function remove(key) {
  sessionStorage.removeItem(key);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  session: {
    save,
    get,
    remove,
  },
};
