/**
 * Datart
 *
 * Copyright 2021
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ConfigProvider, message } from 'antd';
import echartsDefaultTheme from 'app/assets/theme/echarts_default_theme.json';
import { selectLoggedInUser } from 'app/slice/selectors';
import { registerTheme } from 'echarts';
import {StorageKeys, TIME_FORMATTER} from 'globalConstants';
import { antdLocales } from 'locales/i18n';
import { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {BrowserRouter, Route, Switch, useHistory} from 'react-router-dom';
import { GlobalStyles } from 'styles/globalStyles';
import { getToken } from 'utils/auth';
import '../styles/css/style.css';
import useI18NPrefix from './hooks/useI18NPrefix';
import { LoginAuthRoute } from './LoginAuthRoute';
import { LazyActivePage } from './pages/ActivePage/Loadable';
import { LazyAuthorizationPage } from './pages/AuthorizationPage/Loadable';
import { LazyForgetPasswordPage } from './pages/ForgetPasswordPage/Loadable';
import { LazyLoginPage } from './pages/LoginPage/Loadable';
import { LazyRegisterPage } from './pages/RegisterPage/Loadable';
import { useAppSlice } from './slice';
import {
  getConfigInfo,
  getSystemInfo,
  logout,
  setLoggedInUser,
} from './slice/thunks';
import { RemoveTpWatermark, TpWatermark } from './utils/water';
import moment from "moment";
import {getVizBanners} from "./pages/DashBoardPage/pages/Board/slice/thunk";
import {useBoardSlice} from "./pages/DashBoardPage/pages/Board/slice";

registerTheme('default', echartsDefaultTheme);

export function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const logged = !!getToken();
  // const history = useHistory();
  const t = useI18NPrefix('global');
  var loggedInUser = useSelector(selectLoggedInUser);
  console.log("loggedInUser:", loggedInUser)
  // loggedInUser可能会出现null

  useAppSlice();
  useBoardSlice();

  useEffect(() => {
    // 主页设置水印
    if (loggedInUser) {
      console.log("被执行了", loggedInUser);
      RemoveTpWatermark();
      TpWatermark(loggedInUser.name + '|' + loggedInUser.username + '|' + moment().format(TIME_FORMATTER), 'black');
    } else {
      RemoveTpWatermark();
    }
  }, [loggedInUser])

  useLayoutEffect(() => {
    if (logged) {
      dispatch(setLoggedInUser());
    } else {
      if (localStorage.getItem(StorageKeys.LoggedInUser)) {
        message.warning(t('tokenExpired'));
      }
      dispatch(logout());
    }
  }, [dispatch, t, logged]);

  useEffect(() => {
    dispatch(getSystemInfo());
    dispatch(getConfigInfo());
    dispatch(getVizBanners(''));
  }, [dispatch]);

  useEffect(() => {
    const onMessage = (e) => {
      let value = e.data;
      console.log('message:' + value);
      if(value === 'logout') {
        dispatch(
            logout(() => {
              window.location.href = '/';
            }),
        );
      }
    };

    window.addEventListener('message',onMessage,false);
  }, []);

  return (
    <ConfigProvider locale={antdLocales[i18n.language]}>
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - 晨光BI平台"
          defaultTitle="晨光BI平台"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="1236" />
        </Helmet>
        <Switch>
          <Route path="/login" component={LazyLoginPage} />
          <Route path="/register" component={LazyRegisterPage} />
          <Route path="/active" component={LazyActivePage} />
          <Route path="/forgetPassword" component={LazyForgetPasswordPage} />
          <Route
            path="/authorization/:token"
            component={LazyAuthorizationPage}
          />
          <LoginAuthRoute />
        </Switch>
        <GlobalStyles />
      </BrowserRouter>
    </ConfigProvider>
  );
}


