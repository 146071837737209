
// 替换 ${} 中的值
import moment from "moment";

export const getConvertVariable = (str: string) => {
  const val = str.substring(2, str.length - 1);
  if (!val) return '';
  const valArray = val.split(".") as string[];
  switch (valArray[0]) {
    case 'DATE':
      try {
        return getRangeDateV1(valArray[1], parseInt(valArray[3]), valArray[2])[valArray[1]]
      } catch (e) {
        return str;
      }
    case 'DAY':
      try {
        return getRangeDateV2(parseInt(valArray[2]), valArray[1])[valArray[3]]
      } catch (e) {
        return str;
      }
    default:
      return str;
  }
}

const getRangeDateV1 =
  (
    type: string,
    range: number,
    mode: string,
  ): {Y: string, M: string, D: string} => {
    const now = new Date(Date.parse(new Date().toString()));

    switch (type) {
      case 'Y':
        now.setFullYear(mode === 'BEFORE' ? now.getFullYear() - range : now.getFullYear() + range)
        break;
      case 'M':
        now.setMonth(mode === 'BEFORE' ? now.getMonth() - range : now.getMonth() + range)
        break;
      case 'D':
        now.setDate(mode === 'BEFORE' ? now.getDate() - range : now.getDate() + range)
        break;
      default:
        break;
    }
    return {Y: now.getFullYear().toString(), M: (now.getMonth() + 1).toString(), D: now.getDate().toString()};
  }

const getRangeDateV2 =
  (
    range: number,
    mode: string,
  ): {Y: string, M: string, D: string} => {
    const now = new Date(Date.parse(new Date().toString()));
    now.setDate(mode === 'BEFORE' ? now.getDate() - range : now.getDate() + range)
    return {Y: now.getFullYear().toString(), M: (now.getMonth() + 1).toString(), D: now.getDate().toString()};
  }